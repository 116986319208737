import React, { useRef } from "react";
import Icon from "@/components/Icon";
import gridStyles from "@/styles/grids.module.scss";
import { CSSTransition } from "react-transition-group";

interface GridButtonProps {
  selected: boolean;
  icon: string;
  onClick?: (event: React.MouseEvent) => void;
  additionalClasses?: string;
}

export default function GridButton({
  selected,
  icon,
  onClick,
  additionalClasses,
}: GridButtonProps) {
  const buttonRef = useRef(null);

  return (
    <CSSTransition
      in={selected}
      timeout={100}
      classNames={{
        enter: gridStyles.fadeEnter,
        enterActive: gridStyles.fadeEnterActive,
        exit: gridStyles.fadeExit,
        exitActive: gridStyles.fadeExitActive,
      }}
      unmountOnExit
      nodeRef={buttonRef}
    >
      <button
        className={[additionalClasses].join(" ")}
        onClick={onClick}
        ref={buttonRef}
      >
        <Icon icon={icon} width={18} height={18} />
      </button>
    </CSSTransition>
  );
}
