import React, { useContext, useEffect, useRef } from "react";
import utilStyles from "@/styles/utils.module.scss";
import Description from "@/components/Description";
import {
  FullDescriptionView,
  OwnerDescriptionView,
  ShortDescriptionView,
  UserMeView,
} from "@/api/generated";
import {
  DescriptionDispatchContext,
  DescriptionStateContext,
} from "@/contexts/DescriptionContext";
import DescriptionDetail from "@/components/DescriptionDetail";
import SignupOrLogin from "@/components/SignupOrLogin";
import DescriptionBanner from "@/components/DescriptionBanner";

function Descriptions({
  authUser,
  descriptions,
  hasMutualDescriptions = false,
}: {
  authUser?: UserMeView;
  descriptions?: (
    | FullDescriptionView
    | ShortDescriptionView
    | OwnerDescriptionView
  )[];
  hasMutualDescriptions?: boolean;
}) {
  const state = useContext(DescriptionStateContext);
  const dispatch = useContext(DescriptionDispatchContext);
  const descriptionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (state.scrollToUser) {
      const ref = descriptionRefs.current[state.scrollToUser];
      if (ref) {
        ref.scrollIntoView({ behavior: "smooth" });
      }
      dispatch({
        type: "SCROLL_TO_USER",
        payload: {
          userId: "",
        },
      });
    }
  }, [state.scrollToUser]);

  return (
    <>
      <section
        className={[utilStyles.section, utilStyles.sectionContents].join(" ")}
      >
        <h2 className={utilStyles.sectionTitle}>My Dscriptions</h2>

        <div
          className={[utilStyles.widthFull, utilStyles.gridButtonMargin].join(
            " "
          )}
          ref={(el) => {
            if (el) {
              descriptionRefs.current["top"] = el;
            }
          }}
        >
          {descriptions && descriptions?.length > 0 && (
            <div className={utilStyles.posts}>
              {descriptions?.map((post: FullDescriptionView) => (
                <div
                  ref={(el) => {
                    if (post.author && el) {
                      descriptionRefs.current[post.author.id as string] = el;
                    }
                  }}
                  key={post.id}
                >
                  <Description
                    description={post}
                    user={authUser}
                    key={post.id}
                  />
                </div>
              ))}
            </div>
          )}

          <DescriptionBanner
            authUser={authUser}
            hasMutualDescriptions={hasMutualDescriptions}
            hasDescription={descriptions?.length !== 0}
          />

          {!authUser && <SignupOrLogin />}
        </div>
      </section>

      <DescriptionDetail />
    </>
  );
}

export default Descriptions;
