import React from "react";
import layout from "@/styles/layout.module.scss";
import utilStyles from "@/styles/utils.module.scss";
import Avatar from "../Avatar";
import { UserMeView } from "@/api/generated";
import Loading from "@/components/Loading";
import { GridLayoutProvider } from "@/contexts/GridLayoutContext";
import Followers from "@/components/Followers";
import LogoStyledLayout from "@/components/Layout/LogoStyledLayout";
import UserHeaderButtons from "@/components/UserHeaderButtons";

export default function GridProfileLayout({
  authUser,
  user,
  children,
}: {
  authUser?: UserMeView;
  user?: UserMeView;
  children: React.ReactNode;
}) {
  const header = <UserHeaderButtons authUser={authUser} />;

  if (!user) return <Loading />;
  const bioStyle: React.CSSProperties = user.bio?.includes("\n")
    ? { whiteSpace: "pre-wrap" }
    : {};

  return (
    <LogoStyledLayout headerNode={header} authUser={authUser} user={user}>
      <main className={[utilStyles.minField, layout.gridProfile].join(" ")}>
        <div className={layout.profile}>
          <div className={layout.introduction}>
            <span className={layout.name}>{user.full_name}</span>
            <span className={layout.bio} style={bioStyle}>
              {user.bio}
            </span>
            <Followers user={user} />
          </div>
          <Avatar avatar={user.avatar as string} width={90} height={90} />
        </div>

        <GridLayoutProvider>{children}</GridLayoutProvider>
      </main>
    </LogoStyledLayout>
  );
}
