import React from "react";
import { useRouter } from "next/router";
import Api from "@/api";
import { useAuthHeaders } from "@/lib/authHeader";
import { UserMeView } from "@/api/generated";

export default function AskToDscribeButton({
  authUser,
  children,
}: {
  authUser?: UserMeView;
  children: React.ReactNode;
}) {
  const { authHeader } = useAuthHeaders();
  const router = useRouter();

  function clickAsk() {
    if (!authHeader) return;

    if (!authUser) {
      router.push("/login");
      return;
    }

    Api.MyDescriptions.postApiDescriptionsMeCreateInvitation(authHeader).then(
      (res) => {
        router.push(`/account/invite/?code=${res.data.code}`);
      }
    );
  }

  return (
    <div onClick={clickAsk} style={{ width: "100%" }}>
      {children}
    </div>
  );
}
