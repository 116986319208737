import React, { useContext, useEffect } from "react";
import gridStyles from "@/styles/grids.module.scss";
import GridLayout, { Layout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { maxCols, xMargin, yMargin } from "@/lib/gridLayoutConstants";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import { useGridActions } from "@/hooks/useGridActions";
import { useAuthHeaders } from "@/lib/authHeader";
import { makeCardRequestBody } from "@/lib/gridLayoutHelper";

export default function GridLayoutContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const { state } = useContext(GridLayoutContext);
  const { setLayouts, setWidth, setHeight, updateApiCard } = useGridActions();
  const { authHeader } = useAuthHeaders();

  useEffect(() => {
    const handleResize = () => {
      const element = document.querySelector(`.${gridStyles.root}`);
      if (element) {
        const tempWidth = element.getBoundingClientRect().width;
        const innerWidth = tempWidth - xMargin * maxCols;
        const cardWidth = (innerWidth - xMargin) / maxCols;
        const tempHeight = (cardWidth - yMargin) / maxCols;
        const cardHeight = tempWidth > 0 ? tempHeight : 10;

        setWidth(tempWidth);
        setHeight(cardHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setHeight, setWidth]);

  const handleLayoutChange = async (newLayout: Layout[]) => {
    if (!authHeader) return;

    if (JSON.stringify(newLayout) === JSON.stringify(state.layout)) {
      return;
    }

    const oldStateMap: { [key: string]: Layout } = {};
    state.layout.forEach((layout) => {
      oldStateMap[layout.i] = layout;
    });

    setLayouts(newLayout);

    newLayout.forEach((layout) => {
      const oldLayoutItem = oldStateMap[layout.i];
      if (
        oldLayoutItem &&
        oldLayoutItem.x === layout.x &&
        oldLayoutItem.y === layout.y &&
        oldLayoutItem.w === layout.w &&
        oldLayoutItem.h === layout.h
      ) {
        return;
      }

      const card = state.cardList.find((card) => card.id === layout.i);
      if (!card) return;
      const cardRequestBody = makeCardRequestBody(layout, card);
      updateApiCard(layout.i, cardRequestBody);
    });
  };

  return (
    <div className={gridStyles.shadowRoot}>
      <section className={gridStyles.root}>
        <GridLayout
          className="layout grid-layout-root"
          layout={state.layout}
          cols={maxCols}
          margin={[xMargin, yMargin]}
          rowHeight={state.height}
          width={state.width}
          compactType={"vertical"}
          isBounded={true}
          allowOverlap={false}
          resizeHandles={["se"]}
          isDroppable={true}
          draggableHandle={".dragHandle"}
          onLayoutChange={handleLayoutChange}
        >
          {children}
        </GridLayout>
        <div id="dummy-layout"></div>
      </section>
    </div>
  );
}
