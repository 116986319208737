import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { UserMeView } from "@/api/generated";
import gridStyles from "@/styles/grids.module.scss";
import Image from "next/image";

export default function GridEmptyLayout({
  authUser,
}: {
  authUser?: UserMeView;
}) {
  return (
    <>
      {authUser ? (
        <div className={gridStyles.emptyLayout}>
          <Image
            src={"/images/empty-layout.png"}
            layout="responsive"
            width={1032}
            height={1080}
            objectFit="cover"
            priority={true}
          />
        </div>
      ) : (
        <div className={gridStyles.emptyLayout}>
          <Image
            src={"/images/empty-layout-min.png"}
            layout="responsive"
            width={1032}
            height={420}
            objectFit="cover"
            priority={true}
          />
        </div>
      )}
    </>
  );
}
