import React, { useContext } from "react";
import gridStyles from "@/styles/grids.module.scss";
import { CardContent } from "@/types/CardContent";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import GridButton from "@/components/GridItems/GridButton";

export default function GridButtonMove({ card }: { card: CardContent }) {
  const { state } = useContext(GridLayoutContext);

  const selected = state.selectedCardId === card.id;

  return (
    <GridButton
      selected={selected}
      icon="card_drag"
      additionalClasses={[gridStyles.buttonDrag, "dragHandle"].join(" ")}
    />
  );
}
