import React, {
  forwardRef,
  MouseEventHandler,
  ReactNode,
  TouchEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import gridStyles from "@/styles/grids.module.scss";
import { CardContent } from "@/types/CardContent";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import ReactGridLayout from "react-grid-layout";
import CustomContent from "@/components/GridItems/CustomContent";
import { useGridActions } from "@/hooks/useGridActions";
import GridButtonDelete from "@/components/GridItems/GridButtonDelete";
import GridButtonEdit from "@/components/GridItems/GridButtonEditing";
import GridButtonMove from "@/components/GridItems/GridButtonMove";
import { CSSTransition } from "react-transition-group";
import { getCardLink } from "@/lib/gridLayoutHelper";
import { useTouchClick } from "@/hooks/useTouchClick";

interface CustomGridItemProps {
  item: ReactGridLayout.Layout;
  card?: CardContent;
  className?: string;
  onMouseDown?: MouseEventHandler;
  onMouseUp?: MouseEventHandler;
  onTouchEnd?: TouchEventHandler;
  children?: ReactNode;
  readOnly?: boolean;
}

const CustomGridItemComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  CustomGridItemProps
> = (
  {
    item,
    card,
    className,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    children,
    readOnly = false,
    ...props
  },
  ref
) => {
  const { state } = useContext(GridLayoutContext);
  const { selectCardId } = useGridActions();
  const { handleTouchStart, handleTouchEnd, handleItemClick } = useTouchClick(
    onTouchEnd as TouchEventHandler,
    (event: React.MouseEvent | React.TouchEvent) => {
      event.stopPropagation();
      if ("stopImmediatePropagation" in event.nativeEvent) {
        event.nativeEvent.stopImmediatePropagation();
      }
      const target = event.currentTarget as HTMLDivElement;
      const i = target.dataset.id || "";
      const card = state.cardList.find((card: CardContent) => card.id === i);
      if (!card) return;

      if (!readOnly) {
        selectCardId(card.id);
        return;
      }
      const link = getCardLink(card);
      if (link) {
        window.open(link, "_blank");
      }
    }
  );
  const buttonsRef = useRef(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const selected = state.selectedCardId === card?.id;
  const [link, setLink] = useState<string>("");

  useEffect(() => {
    if (selected && itemRef.current) {
      const rect = itemRef.current.getBoundingClientRect();
      const scrollContainer = window;

      const offset = -50;
      const newScrollTopPosition = rect.top + scrollContainer.scrollY + offset;

      window.scrollTo({
        top: newScrollTopPosition,
        behavior: "smooth",
      });
    }
  }, [selected]);

  useEffect(() => {
    if (card) {
      setLink(getCardLink(card));
    }
  }, [card]);

  if (!card) return null;

  return (
    <div
      className={[
        gridStyles.gridItem,
        className,
        selected ? gridStyles.selected : "",
        link ? gridStyles.hasLink : "",
        gridStyles[`layout${card.layout}`],
      ].join(" ")}
      ref={(el) => {
        if (typeof ref === "function") {
          ref(el);
        } else if (ref && el) {
          (ref as React.MutableRefObject<HTMLDivElement>).current = el;
        }
        if (el) {
          (itemRef as React.MutableRefObject<HTMLDivElement>).current = el;
        }
      }}
      onClick={handleItemClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      {...props}
    >
      <div
        className={[
          gridStyles.gridItemContent,
          gridStyles[`layout${card.layout}`],
          selected ? gridStyles.gridItemContentSelected : "",
        ].join(" ")}
      >
        <CustomContent card={card} item={item} readOnly={readOnly}>
          {children}
        </CustomContent>
      </div>

      <CSSTransition
        in={selected}
        timeout={300}
        classNames={{
          enter: gridStyles.fadeEnter,
          enterActive: gridStyles.fadeEnterActive,
          exit: gridStyles.fadeExit,
          exitActive: gridStyles.fadeExitActive,
        }}
        unmountOnExit
        nodeRef={buttonsRef}
      >
        <div ref={buttonsRef}>
          <GridButtonDelete card={card} />
          <GridButtonEdit card={card} />
          <GridButtonMove card={card} />
        </div>
      </CSSTransition>
      {/*  resize button is provided by the CSS .react-resizable-handle. */}
    </div>
  );
};
export default forwardRef(CustomGridItemComponent);
