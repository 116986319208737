import React, { createContext, Dispatch, ReactNode, useReducer } from "react";
import { CardContent } from "@/types/CardContent";
import GridLayout from "react-grid-layout";

export interface State {
  width: number;
  height: number;
  selectedCardId: string | null;
  layout: GridLayout.Layout[];
  cardList: CardContent[];
}

const initialState: State = {
  width: 0,
  height: 0,
  selectedCardId: null,
  layout: [],
  cardList: [],
};

// Actions
type Action =
  | { type: "SET_WIDTH"; payload: number }
  | { type: "SET_HEIGHT"; payload: number }
  | { type: "SET_SELECTED_CARD_ID"; payload: string | null }
  | { type: "SET_LAYOUT"; payload: GridLayout.Layout[] }
  | { type: "SET_CARD_LIST"; payload: CardContent[] };

// Reducer
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_WIDTH":
      return { ...state, width: action.payload };

    case "SET_HEIGHT":
      return { ...state, height: action.payload };

    case "SET_SELECTED_CARD_ID": {
      if (state.selectedCardId === action.payload) return state;

      const selectedCard = state.cardList.find(
        (card) => card.id === state.selectedCardId
      );
      if (selectedCard) {
        return {
          ...state,
          selectedCardId: action.payload,
          cardList: state.cardList.map((card: CardContent) =>
            card.id === selectedCard.id ? { ...card, editing: false } : card
          ),
        };
      }

      return { ...state, selectedCardId: action.payload };
    }

    case "SET_LAYOUT":
      return { ...state, layout: action.payload };

    case "SET_CARD_LIST": {
      return {
        ...state,
        cardList: action.payload,
      };
    }

    default:
      return state;
  }
};

// Context
type GridLayoutContextType = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const GridLayoutContext = createContext<GridLayoutContextType>(
  undefined as any
);

// Provider
type GridLayoutProviderProps = {
  children: ReactNode;
};

export const GridLayoutProvider: React.FC<GridLayoutProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GridLayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </GridLayoutContext.Provider>
  );
};
