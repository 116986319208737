import { Layout } from "react-grid-layout";
import {
  CardContent,
  ImageCardContent,
  LinkCardContent,
  TextCardContent,
} from "@/types/CardContent";
import {
  CardRequestBody,
  CardView,
  ImageCardView,
  LinkCardView,
  TextCardView,
} from "@/api/generated";
import { snsLinkCardSourceSites } from "@/lib/gridLayoutConstants";
import { isBrightColor } from "@/lib/layoutHelper";

export const isSnsLinkCard = (sourceSite: string): boolean => {
  return snsLinkCardSourceSites.includes(sourceSite);
};

export const isFontBlack = (backgroundColor: string): boolean => {
  return isBrightColor(backgroundColor);
};

export const truncateText = (
  container: HTMLDivElement | null,
  content: string
) => {
  if (container) {
    container.innerHTML = content;

    while (
      (container.scrollHeight > container.offsetHeight ||
        container.scrollWidth > container.offsetWidth) &&
      content.length > 3
    ) {
      content = content.slice(0, -3);
      container.innerHTML = content + "...";
    }
  }
};

export const getCardLink = (card: CardContent): string => {
  const cardContent = getCardContent(card);

  return cardContent.url || "";
};

const getCardContent = (
  card: CardContent
): TextCardContent | ImageCardContent | LinkCardContent => {
  switch (card.type) {
    case "text":
      return card.typeContent as TextCardContent;
    case "image":
      return card.typeContent as ImageCardContent;
    case "link":
      return card.typeContent as LinkCardContent;
  }
};

const defaultCardRequestBody: CardRequestBody = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  layout: "",
  type: "",
  content: "",
  url: "",
  align: "",
  valign: "",
  bg_color: "",
};

export const makeCardRequestBody = (
  gridLayout: Layout,
  card: CardContent
): CardRequestBody => {
  const { x, y, w, h } = gridLayout;
  const { layout, type, typeContent } = card;

  let cardContent = {};
  if (type === "text") {
    const textContent = typeContent as TextCardContent;
    cardContent = {
      align: textContent.align,
      valign: textContent.valign,
      bg_color: textContent.bgColor,
      content: textContent.content,
      url: textContent.url,
    };
  } else if (type === "image") {
    const imageContent = typeContent as ImageCardContent;
    cardContent = {
      url: imageContent.url,
      caption: imageContent.caption,
      image: imageContent.image,
      image_type: imageContent.imageType,
      image_id: imageContent.imageId,
    };
  } else if (type === "link") {
    const linkContent = typeContent as LinkCardContent;
    cardContent = {
      url: linkContent.url,
      title: linkContent.title,
      accountId: linkContent.accountId,
      description: linkContent.description,
      image: linkContent.image,
      image_id: linkContent.imageId,
    };
  }

  return {
    ...defaultCardRequestBody,
    x,
    y,
    w,
    h,
    layout,
    type,
    ...cardContent,
  };
};

export const convertCardViewToLayout = (cardView: CardView): Layout => {
  return {
    i: cardView.id,
    x: cardView.x,
    y: cardView.y,
    w: cardView.w,
    h: cardView.h,
  } as Layout;
};

export const convertCardViewToCardContent = (
  cardView: CardView
): CardContent => {
  const typeContent =
    cardView.type === "text"
      ? convertTextCardViewToTextCardContent(cardView.content as TextCardView)
      : cardView.type === "image"
      ? convertImageCardViewToImageCardContent(
          cardView.content as ImageCardView
        )
      : cardView.type === "link"
      ? convertLinkCardViewToLinkCardContent(cardView.content as LinkCardView)
      : null;

  return {
    id: cardView.id,
    type: cardView.type,
    layout: cardView.layout,
    editing: false,
    typeContent: typeContent,
  } as CardContent;
};

const convertTextCardViewToTextCardContent = (
  textCardView: TextCardView
): TextCardContent => {
  return {
    align: textCardView.align,
    valign: textCardView.valign,
    bgColor: textCardView.bg_color,
    content: textCardView.content,
    url: textCardView.url,
  } as TextCardContent;
};

const convertImageCardViewToImageCardContent = (
  imageCardView: ImageCardView
): ImageCardContent => {
  return {
    imageType: imageCardView.image_type,
    url: imageCardView.url,
    caption: imageCardView.caption,
    image: imageCardView.image,
    imageId: imageCardView.image_id,
  } as ImageCardContent;
};

const convertLinkCardViewToLinkCardContent = (
  linkCardView: LinkCardView
): LinkCardContent => {
  return {
    url: linkCardView.url,
    sourceSite: linkCardView.source_site,
    title: linkCardView.title,
    accountId: linkCardView.account_id,
    description: linkCardView.description,
    image: linkCardView.image,
    imageId: linkCardView.image_id,
  } as LinkCardContent;
};
