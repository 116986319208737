import React from "react";
import Link from "next/link";
import utilStyles from "@/styles/utils.module.scss";
import AppLogo from "../AppLogo";
import Layout from "@/components/Layout/Layout";
import { UserMeView } from "@/api/generated";
import { getLogoImageSrc } from "@/lib/layoutHelper";

export default function LogoStyledLayout({
  authUser,
  user,
  headerNode,
  children,
}: {
  authUser?: UserMeView;
  user?: UserMeView;
  headerNode: React.ReactNode;
  children: React.ReactNode;
}) {
  const coverImage = user?.cover_image || "coverImage01";
  const coverImageSrc = getLogoImageSrc(coverImage);

  const header = (
    <header className={utilStyles.header}>
      <Link href={authUser ? "/home" : "/"}>
        <a>
          <AppLogo height={17} src={coverImageSrc} />
        </a>
      </Link>
      {headerNode && headerNode}
    </header>
  );

  return <Layout header={header}>{children}</Layout>;
}
