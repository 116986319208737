import React from "react";
import utilStyles from "@/styles/utils.module.scss";
import Image from "next/image";
import AskToDscribeButton from "@/components/AskToDscribeButton";
import { UserMeView } from "@/api/generated";
import { useRouter } from "next/router";

export default function DescriptionBanner({
  authUser,
  hasMutualDescriptions,
  hasDescription,
}: {
  authUser?: UserMeView;
  hasMutualDescriptions?: boolean;
  hasDescription?: boolean;
}) {
  const router = useRouter();

  if (hasMutualDescriptions) {
    return null;
  }

  if (!authUser) {
    return (
      <div
        onClick={() => router.push("/signup")}
        className={utilStyles.descriptionBanner}
      >
        <Image
          src={
            hasDescription
              ? "/images/banner-signup-min.png"
              : "/images/banner-signup-full.png"
          }
          alt="Sign up to Dscribe.me"
          width={716}
          height={hasDescription ? 560 : 850}
          layout="responsive"
        />
      </div>
    );
  }

  return (
    <AskToDscribeButton authUser={authUser}>
      <div className={utilStyles.descriptionBanner}>
        <Image
          src={
            hasDescription
              ? "/images/banner-description-min.png"
              : "/images/banner-description-full.png"
          }
          alt="Ask to Dscribe.me"
          width={716}
          height={hasDescription ? 560 : 760}
          layout="responsive"
        />
      </div>
    </AskToDscribeButton>
  );
}
