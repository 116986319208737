import React, { useRef, useState } from "react";

interface TouchStartPos {
  x: number;
  y: number;
}

export const useTouchClick = (
  onTouchEnd: ((event: React.TouchEvent) => void) | null,
  onItemClick: (event: React.MouseEvent | React.TouchEvent) => void
) => {
  const lastEventTypeRef = useRef<string | null>(null);
  const [touchStartPos, setTouchStartPos] = useState<TouchStartPos | null>(
    null
  );

  const handleTouchStart = (event: React.TouchEvent) => {
    const touch = event.touches[0];
    setTouchStartPos({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEndInternal = (event: React.TouchEvent) => {
    if (!touchStartPos) return;
    if (onTouchEnd) onTouchEnd(event);

    const touch = event.changedTouches[0];
    const deltaX = Math.abs(touch.clientX - touchStartPos.x);
    const deltaY = Math.abs(touch.clientY - touchStartPos.y);

    if (deltaX < 10 && deltaY < 10) {
      handleItemClickInternal(event);
    }
    setTouchStartPos(null);
  };

  const handleItemClickInternal = (
    event: React.MouseEvent | React.TouchEvent
  ) => {
    if (isEventFiredRecently(event)) {
      event.stopPropagation();
      return;
    }
    lastEventTypeRef.current = event.type;

    onItemClick(event);

    setTimeout(() => {
      clearLastEventType();
    }, 500);
  };

  const isEventFiredRecently = (event: React.MouseEvent | React.TouchEvent) => {
    return lastEventTypeRef.current && lastEventTypeRef.current !== event.type;
  };

  const clearLastEventType = () => {
    lastEventTypeRef.current = null;
  };

  return {
    handleTouchStart,
    handleTouchEnd: handleTouchEndInternal,
    handleItemClick: handleItemClickInternal,
  };
};
