import React from "react";
import Link from "next/link";
import layout from "@/styles/layout.module.scss";
import Avatar from "./Avatar";
import { useRouter } from "next/router";
import { UserMeView } from "@/api/generated";
import Icon from "@/components/Icon";

export default function UserHeaderButtons({
  authUser,
}: {
  authUser?: UserMeView;
}) {
  const router = useRouter();
  const clickNotificationHandler = () => {
    if (router.pathname === "/profile/notifications") {
      router.reload();
      return;
    }

    router.push("/profile/notifications");
  };

  return (
    <div
      className={[layout.gridHeaderActions, !authUser ? layout.guest : ""].join(
        " "
      )}
    >
      <Link href="/search/users">
        <button className={layout.search}>
          <Icon icon="search_black" width={20} height={20} />
        </button>
      </Link>
      {authUser && (
        <>
          <button
            className={layout.myNotification}
            onClick={clickNotificationHandler}
          >
            <Icon icon="notification_black" width={24} height={24} />
            {authUser?.has_notification && (
              <span className={layout.myNotificationBadge} />
            )}
            {router.pathname === "/profile/notifications" && (
              <div className={layout.actionSelected} />
            )}
          </button>
          <Link href="/profile/edit">
            <div>
              <Avatar avatar={authUser?.avatar} width={28} height={28} />
              {router.pathname.match(/profile\/(edit|described|settings)/g) && (
                <div className={layout.actionSelected} />
              )}
            </div>
          </Link>
        </>
      )}
    </div>
  );
}
