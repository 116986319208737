import React from "react";
import utilStyles from "@/styles/utils.module.scss";
import { useRouter } from "next/router";

export default function SignupOrLogin() {
  const router = useRouter();
  return (
    <section className={utilStyles.signUpSection}>
      <button
        onClick={() => router.push("/signup")}
        className={utilStyles.signUp}
      >
        Sign up
      </button>
      <button
        onClick={() => router.push("/login")}
        className={utilStyles.login}
      >
        Log in
      </button>
    </section>
  );
}
