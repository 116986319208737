import React, { memo, useContext } from "react";
import { CardContent } from "@/types/CardContent";
import GridLayout from "react-grid-layout";
import ImageCard from "@/components/GridItems/ImageCard";
import LinkCard from "@/components/GridItems/LinkCard";
import TextCard from "@/components/GridItems/TextCard";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";

const CustomContent = memo(
  ({
    card,
    item,
    readOnly,
    children,
  }: {
    card: CardContent;
    item: GridLayout.Layout;
    readOnly: boolean;
    children: React.ReactNode;
  }) => {
    const { state } = useContext(GridLayoutContext);

    const selected = state.selectedCardId === card.id;

    let ContentComponent;
    switch (card.type) {
      case "image":
        ContentComponent = ImageCard;
        break;
      case "link":
        ContentComponent = LinkCard;
        break;
      case "text":
        ContentComponent = TextCard;
        break;
      default: {
        const DefaultComponent = ({
          children,
        }: {
          children: React.ReactNode;
        }) => <>{children}</>;
        DefaultComponent.displayName = "DefaultComponent";
        ContentComponent = DefaultComponent;
      }
    }

    return (
      <ContentComponent card={card} item={item} readOnly={readOnly}>
        {selected ? children : null}
      </ContentComponent>
    );
  }
);
CustomContent.displayName = "CustomContent";

export default CustomContent;
