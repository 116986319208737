import React from "react";
import Image from "next/image";

interface Props {
  sourceSite: string;
}

const imageMap: Record<string, string> = {
  YouTube: "/images/grid-logo-youtube.svg",
  Twitter: "/images/grid-logo-twitter.svg",
  Facebook: "/images/grid-logo-facebook.svg",
  Instagram: "/images/grid-logo-instagram.svg",
  TikTok: "/images/grid-logo-tiktok.svg",
  Snapchat: "/images/grid-logo-snapchat.svg",
  Threads: "/images/grid-logo-threads.svg",
  Line: "/images/grid-logo-line.svg",
  Note: "/images/grid-logo-note.svg",
  Discord: "/images/grid-logo-discord.svg",
  GitHub: "/images/grid-logo-github.svg",
  Other: "/images/grid-logo-default.svg",
};

const LinkCardIcon: React.FC<Props> = ({ sourceSite }) => {
  const imgSrc = imageMap[sourceSite] || imageMap["Other"];

  return <Image src={imgSrc} width={80} height={80} />;
};

export default LinkCardIcon;
