import GridLayout from "react-grid-layout";
import { CardContent } from "@/types/CardContent";

export const maxCols = 2;
export const xMargin = 14;
export const yMargin = 14;

export const snsLinkCardSourceSites = [
  "Twitter",
  "Instagram",
  "Facebook",
  "Snapchat",
  "Threads",
  "Tiktok",
];

export const defaultItemLayout: GridLayout.Layout = {
  i: "default",
  x: 0,
  y: 999,
  w: 1,
  h: 2,
  minW: 1,
  minH: 1,
  maxW: 4,
  maxH: 8,
  static: false,
  isDraggable: true,
  isResizable: true,
  resizeHandles: ["se"],
};

export const defaultTextCard: CardContent = {
  id: "new",
  type: "text",
  layout: "2x2",
  editing: false,
  typeContent: {
    align: "left",
    valign: "flex-start",
    bgColor: "#FFFFFF",
    url: "",
    content: "",
  },
};

export const defaultImageCard: CardContent = {
  id: "new",
  type: "image",
  layout: "2x2",
  editing: false,
  typeContent: {
    imageType: "image",
    url: "",
    image: "",
    imageId: "",
    caption: "",
  },
};

export const defaultLinkCard: CardContent = {
  id: "new",
  type: "link",
  layout: "2x2",
  editing: false,
  typeContent: {
    url: "",
    title: "",
    accountId: "",
    description: "",
    image: "",
    imageId: "",
    sourceSite: "",
  },
};
