import React, { useContext, useEffect } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import CustomGridItem from "@/components/GridItems/CustomGridItem";
import GridLayoutContainer from "@/components/GridItems/GridLayoutContainer";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import { CardContent } from "@/types/CardContent";
import { useGridActions } from "@/hooks/useGridActions";
import { UserMeView } from "@/api/generated";
import Api from "@/api";
import { Layout } from "react-grid-layout";
import {
  convertCardViewToCardContent,
  convertCardViewToLayout,
} from "@/lib/gridLayoutHelper";
import GridEmptyLayout from "@/components/GridItems/GridEmptyLayout";

export default function GridLayoutSection({
  authUser,
  user,
  onChildLoaded,
}: {
  authUser?: UserMeView;
  user: UserMeView;
  onChildLoaded?: () => void;
}) {
  const { state } = useContext(GridLayoutContext);
  const { setLayouts, setCardList } = useGridActions();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gridLayouts = await Api.GridLayout.getApiGridLayoutsShow(
          user.id as string
        );

        if (!gridLayouts.data.cards) return;

        const layouts: Layout[] = [];
        const cardList: CardContent[] = [];
        gridLayouts.data.cards.forEach((card) => {
          layouts.push(convertCardViewToLayout(card));
          cardList.push(convertCardViewToCardContent(card));
        });
        setLayouts(layouts);
        setCardList(cardList);
        setTimeout(() => {
          onChildLoaded?.();
        }, 300);
      } catch (error) {
        console.log(error);
        onChildLoaded?.();
      }
    };

    fetchData();
  }, [user, setLayouts, setCardList]);

  return (
    <>
      {state.layout.length === 0 ? (
        <GridEmptyLayout authUser={authUser} />
      ) : (
        <GridLayoutContainer>
          {state.layout.map((item) => (
            <CustomGridItem
              key={item.i}
              data-id={item.i}
              item={item}
              card={state.cardList.find((card) => card.id === item.i)}
              readOnly={authUser?.id !== user.id}
            ></CustomGridItem>
          ))}
        </GridLayoutContainer>
      )}
    </>
  );
}
