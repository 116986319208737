import React, { useContext, useEffect, useState } from "react";
import layout from "@/styles/layout.module.scss";
import Avatar from "@/components/Avatar";
import {
  DescriptionDispatchContext,
  DescriptionStateContext,
} from "@/contexts/DescriptionContext";
import { ShortUserView, UserMeView } from "@/api/generated";
import Icon from "@/components/Icon";
import utilStyles from "@/styles/utils.module.scss";

export default function Followers({ user }: { user: UserMeView }) {
  const state = useContext(DescriptionStateContext);
  const dispatch = useContext(DescriptionDispatchContext);
  const [userIcons, setUserIcons] = useState<ShortUserView[] | UserMeView[]>(
    []
  );

  useEffect(() => {
    if (state.descriptions.length === 0) {
      return;
    }
    const userIcons: ShortUserView[] = [];
    state.descriptions
      .filter(function (description) {
        return description.owner?.id === user.id;
      })
      .forEach((description) => {
        if (!description.author) {
          return;
        }
        userIcons.push(description.author);
      });
    setUserIcons(userIcons);
  }, [state.descriptions]);

  const handleIconClick = (userId: string) => {
    dispatch({
      type: "SCROLL_TO_USER",
      payload: {
        userId: userId,
      },
    });
  };

  return (
    <div className={layout.followers}>
      {userIcons.length === 0 ? (
        <>
          <div className={layout.icons}>
            <Icon icon="no_follower" width={30} height={30} />
          </div>
          <span>(0)</span>
        </>
      ) : (
        <>
          <div className={layout.icons}>
            {userIcons.slice(0, 3).map((userIcon: ShortUserView) => (
              <div
                key={userIcon.id}
                onClick={() => handleIconClick(userIcon.id as string)}
                className={layout.avatar}
              >
                <Avatar
                  key={userIcon.id}
                  avatar={userIcon.avatar}
                  width={30}
                  height={30}
                />
              </div>
            ))}
            {userIcons.length === 0 && (
              <div className={utilStyles.avatar}></div>
            )}
          </div>
          <span onClick={() => handleIconClick("top")}>
            ({userIcons.length})
          </span>
        </>
      )}
    </div>
  );
}
