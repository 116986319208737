import { useCallback, useContext } from "react";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import { CardContent, CardType } from "@/types/CardContent";
import GridLayout, { Layout } from "react-grid-layout";
import { makeCardRequestBody } from "@/lib/gridLayoutHelper";
import { useAuthHeaders } from "@/lib/authHeader";
import Api from "@/api";
import { CardRequestBody } from "@/api/generated";

export function useGridActions() {
  const { state, dispatch } = useContext(GridLayoutContext);
  const { authHeader } = useAuthHeaders();

  const setLayouts = useCallback(
    (layout: GridLayout.Layout[]) => {
      dispatch({ type: "SET_LAYOUT", payload: layout });
    },
    [dispatch]
  );

  const updateLayout = useCallback(
    (id: string, updatedProperties: Partial<GridLayout.Layout>) => {
      setLayouts(
        state.layout.map((item: GridLayout.Layout) => {
          if (item.i === id) {
            return {
              ...item,
              ...updatedProperties,
            };
          }
          return item;
        })
      );
    },
    [setLayouts, state.layout]
  );

  const setCardList = useCallback(
    (cardList: CardContent[]) => {
      dispatch({ type: "SET_CARD_LIST", payload: cardList });
    },
    [dispatch]
  );

  const addCard = useCallback(
    (card: CardContent) => {
      setCardList([...state.cardList, card]);
    },
    [setCardList, state.cardList]
  );

  const updateCard = useCallback(
    (id: string, updatedProperties: Partial<CardContent>) => {
      setCardList(
        state.cardList.map((item: CardContent) => {
          if (item.id === id) {
            return {
              ...item,
              ...updatedProperties,
            };
          }
          return item;
        })
      );
    },
    [setCardList, state.cardList]
  );

  const updateApiCard = useCallback(
    (id: string, cardRequestBody: CardRequestBody) => {
      if (!authHeader) return;

      Api.MyGridLayout.putApiGridLayoutsMeUpdate(
        id,
        cardRequestBody,
        authHeader
      )
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [authHeader]
  );

  const selectCardId = useCallback(
    (id: string | null) => {
      dispatch({ type: "SET_SELECTED_CARD_ID", payload: id });
    },
    [dispatch]
  );

  const deleteItem = useCallback(
    (id: string) => {
      setLayouts(state.layout.filter((item: Layout) => item.i !== id));
      setCardList(state.cardList.filter((card: CardContent) => card.id !== id));
      selectCardId(null);
    },
    [setLayouts, state.layout, state.cardList, setCardList, selectCardId]
  );

  const setWidth = useCallback(
    (width: number) => {
      dispatch({ type: "SET_WIDTH", payload: width });
    },
    [dispatch]
  );

  const setHeight = useCallback(
    (height: number) => {
      dispatch({ type: "SET_HEIGHT", payload: height });
    },
    [dispatch]
  );

  const isSelectedCardType = useCallback(
    function (type: CardType) {
      const selectedCard =
        state.cardList.find(
          (card: CardContent) => card.id === state.selectedCardId
        ) || null;

      return selectedCard?.type === type;
    },
    [state.cardList, state.selectedCardId]
  );

  const isEditing = useCallback(() => {
    const selectedCard =
      state.cardList.find(
        (card: CardContent) => card.id === state.selectedCardId
      ) || null;

    return selectedCard?.editing === true;
  }, [state.cardList, state.selectedCardId]);

  const resetSelectedCard = useCallback(() => {
    const selectedCard =
      state.cardList.find(
        (card: CardContent) => card.id === state.selectedCardId
      ) || null;

    if (selectedCard?.editing) {
      updateCard(selectedCard.id, { editing: false });
      const layout = state.layout.find(
        (layout) => layout.i === selectedCard.id
      );
      if (!layout) return;
      const cardRequestBody = makeCardRequestBody(layout, selectedCard);
      updateApiCard(selectedCard.id, cardRequestBody);
    } else {
      selectCardId(null);
    }
  }, [state.cardList, state.selectedCardId, updateCard, selectCardId]);

  return {
    setLayouts,
    updateLayout,
    setCardList,
    addCard,
    updateCard,
    updateApiCard,
    selectCardId,
    deleteItem,
    setWidth,
    setHeight,
    isSelectedCardType,
    isEditing,
    resetSelectedCard,
  };
}
