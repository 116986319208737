import React, { useContext } from "react";
import { useGridActions } from "@/hooks/useGridActions";
import gridStyles from "@/styles/grids.module.scss";
import { CardContent } from "@/types/CardContent";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import GridButton from "@/components/GridItems/GridButton";
import { useBaseActions } from "@/hooks/useBaseActions";
import { makeCardRequestBody } from "@/lib/gridLayoutHelper";

export default function GridButtonEdit({ card }: { card: CardContent }) {
  const { state } = useContext(GridLayoutContext);
  const { updateCard, updateApiCard } = useGridActions();
  const { showModal } = useBaseActions();

  const handleEditClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (card.type === "text") {
      updateCard(card.id, { editing: true });
    }

    if (card.type === "image") {
      showModal("MODAL_EDIT_IMAGE_CARD");
    }

    if (card.type === "link") {
      showModal("MODAL_EDIT_LINK_CARD");
    }
  };

  const handleDoneClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    updateCard(card.id, { editing: false });

    const layout = state.layout.find((item) => item.i === card.id);
    if (!layout) return;

    const cardRequestBody = makeCardRequestBody(layout, card);
    updateApiCard(card.id, cardRequestBody);
  };

  const selected = state.selectedCardId === card.id;

  return (
    <GridButton
      selected={selected}
      icon={card.editing ? "card_editing" : "card_edit"}
      onClick={card.editing ? handleDoneClick : handleEditClick}
      additionalClasses={
        card.editing ? gridStyles.buttonEditing : gridStyles.buttonEdit
      }
    />
  );
}
