import React, { useContext, useEffect, useRef, useState } from "react";
import gridStyles from "@/styles/grids.module.scss";
import Image from "next/image";
import { CardContent, LinkCardContent } from "@/types/CardContent";
import ReactGridLayout from "react-grid-layout";
import { isSnsLinkCard, truncateText } from "@/lib/gridLayoutHelper";
import { yMargin } from "@/lib/gridLayoutConstants";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import LinkCardIcon from "@/components/GridItems/LinktemIcon";

export default function LinkCard({
  card,
  item,
  children,
}: {
  card: CardContent;
  item: ReactGridLayout.Layout;
  children: React.ReactNode;
}) {
  const { state } = useContext(GridLayoutContext);
  const linkCardContent = card.typeContent as LinkCardContent;
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(state.height * item.h + yMargin * (item.h - 1));
  }, [state.height, item]);

  useEffect(() => {
    const content = linkCardContent.title || "Please edit the title";
    if (!titleRef.current) return;

    setTimeout(() => {
      truncateText(titleRef.current, content);
    }, 100);
  }, [linkCardContent.title, item.w, item.h]);

  useEffect(() => {
    const content = linkCardContent.description || "";
    if (!contentRef.current) return;

    setTimeout(() => {
      truncateText(contentRef.current, content);
    }, 100);
  }, [linkCardContent.description, item.w, item.h]);

  const selected = card.id === state.selectedCardId;
  const isSns = isSnsLinkCard(linkCardContent.sourceSite);

  return (
    <div
      className={[
        gridStyles.linkItem,
        gridStyles[`layout${card.layout}`],
        !isSns ? gridStyles.ogp : "",
        selected ? gridStyles.linkItemSelected : "",
      ].join(" ")}
      ref={containerRef}
      style={{
        maxHeight: `${height}px`,
      }}
    >
      <div className={gridStyles.linkItemHeader} ref={headerRef}>
        <div className={gridStyles.linkItemHeaderIcon}>
          <LinkCardIcon sourceSite={linkCardContent.sourceSite} />
        </div>
        <div className={gridStyles.linkItemHeaderTitleSection}>
          <span ref={titleRef} className={gridStyles.title}>
            {linkCardContent.title}
          </span>
          <span className={gridStyles.accountId}>
            {linkCardContent.accountId}
          </span>
        </div>
      </div>
      {card.layout !== "4x1" && linkCardContent.description && (
        <div
          className={[
            gridStyles.linkItemBody,
            isSns ? gridStyles.sns : "",
          ].join(" ")}
          ref={contentRef}
        >
          {linkCardContent.description}
        </div>
      )}
      {card.layout === "4x4" && linkCardContent.image && (
        <div
          className={gridStyles.linkItemThumbnailContainer}
          style={{
            maxHeight:
              (containerRef.current?.clientWidth || 340) -
              ((headerRef.current?.clientHeight || 47) + 10) - // margin-top: 10
              (contentRef.current?.clientHeight || 50) -
              28, // padding-y: 28
          }}
        >
          <div
            className={gridStyles.linkItemThumbnail}
            style={{
              maxHeight: (containerRef.current?.clientWidth || 340) / 2,
            }}
          >
            <Image
              src={linkCardContent.image}
              layout="fill"
              objectFit="cover"
            />
          </div>
        </div>
      )}
    </div>
  );
}
