import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import gridStyles from "@/styles/grids.module.scss";
import { CardContent, TextCardContent } from "@/types/CardContent";
import ReactGridLayout from "react-grid-layout";
import { useGridActions } from "@/hooks/useGridActions";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import { yMargin } from "@/lib/gridLayoutConstants";
import dynamic from "next/dynamic";
import { ReactQuillProps } from "react-quill";
import { isFontBlack, truncateText } from "@/lib/gridLayoutHelper";
import chroma from "chroma-js";

interface QuillNoSSRWrapperProps extends ReactQuillProps {
  forwardedRef: React.RefObject<any>;
}

export const QuillNoSSRWrapper = dynamic(
  async () => {
    const { default: RQ } = await import("react-quill");
    // eslint-disable-next-line react/display-name
    return ({ forwardedRef, ...props }: QuillNoSSRWrapperProps) => (
      <RQ ref={forwardedRef} {...props} />
    );
  },
  { ssr: false }
);

export default function TextCard({
  children,
  item,
  card,
}: {
  children: React.ReactNode;
  item: ReactGridLayout.Layout;
  card: CardContent;
}) {
  const { state } = useContext(GridLayoutContext);
  const textCardContent = card.typeContent as TextCardContent;
  const { updateCard } = useGridActions();
  const [isMounted, setIsMounted] = useState(false);
  const quillRef = useRef<any>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleChange = (content: string) => {
    updateCard(card.id, {
      ...card,
      typeContent: {
        ...textCardContent,
        content: content,
      },
    });

    const quillElement = document.querySelector(`#quill-editor-${card.id}`);
    if (quillElement) {
      const parentDiv = quillElement.parentElement;
      if (parentDiv && quillElement.scrollHeight > parentDiv.clientHeight) {
        quillElement.scrollTop =
          quillElement.scrollHeight - parentDiv.clientHeight;
      }
    }
  };

  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(state.height * item.h + yMargin * (item.h - 1) - 32 + 12);
  }, [state.height, item]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (card.editing && quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      const length = quillEditor.getLength();
      quillEditor.setSelection(length);
      quillRef.current.focus();
    }
  }, [card.editing]);

  const innerColor = chroma(textCardContent.bgColor).darken(0.3).hex();

  useEffect(() => {
    truncateText(contentRef.current, textCardContent.content);
  }, [height, textCardContent.content, card.editing, item.w]);

  const selected = card.id === state.selectedCardId;

  const textItemClasses = useMemo(() => {
    const baseClasses = [gridStyles.textItem];
    if (card.editing) {
      baseClasses.push(gridStyles.textItemEdit);
    }
    if (selected) {
      baseClasses.push(gridStyles.textItemSelected);
    }
    return baseClasses.join(" ");
  }, [card.editing, selected]);

  return (
    <div
      className={textItemClasses}
      style={{
        textAlign: textCardContent.align,
        alignItems: textCardContent.valign,
        backgroundColor: textCardContent.bgColor,
      }}
    >
      {isMounted && (
        <>
          <QuillNoSSRWrapper
            id={`quill-editor-${card.id}`}
            forwardedRef={quillRef}
            className={[
              "cardInput",
              gridStyles.quillEditor,
              isFontBlack(textCardContent.bgColor)
                ? gridStyles.fontBlack
                : gridStyles.fontWhite,
            ].join(" ")}
            value={textCardContent.content}
            placeholder="Add note..."
            onChange={handleChange}
            style={{
              display: card.editing ? "flex" : "none",
              alignItems: textCardContent.valign,
              backgroundColor: innerColor,
            }}
            theme="bubble"
            modules={{
              toolbar: [["bold"]],
            }}
          />
          <div
            ref={contentRef}
            className={[
              gridStyles.textContent,
              isFontBlack(textCardContent.bgColor)
                ? gridStyles.fontBlack
                : gridStyles.fontWhite,
            ].join(" ")}
            dangerouslySetInnerHTML={{ __html: textCardContent.content }}
            style={{
              maxHeight: `${height}px`,
              display: card.editing ? "none" : "flex",
            }}
          />
        </>
      )}
      {card.layout === "Resize" ? children : null}
    </div>
  );
}
