import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import gridStyles from "@/styles/grids.module.scss";
import { CardContent, ImageCardContent } from "@/types/CardContent";
import GridLayout from "react-grid-layout";
import Icon from "@/components/Icon";
import { useTouchClick } from "@/hooks/useTouchClick";

interface ImageCardProps {
  card: CardContent;
  item: GridLayout.Layout;
  readOnly: boolean;
  children: React.ReactNode;
}

export default function ImageCard({
  card,
  item,
  readOnly,
  children,
}: ImageCardProps) {
  const imageCardContent = card.typeContent as ImageCardContent;
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [dimensions, setDimensions] = useState({ width: 100, height: 100 });
  const [muted, setMuted] = useState(true);
  const { handleTouchStart, handleTouchEnd, handleItemClick } = useTouchClick(
    null,
    (event: React.MouseEvent | React.TouchEvent) => {
      event.stopPropagation();
      if (!videoRef.current) return;

      videoRef.current.muted = !videoRef.current.muted;
      setMuted(videoRef.current.muted);
    }
  );

  useEffect(() => {
    const observeSize = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setDimensions({ width: clientWidth, height: clientHeight as number });
      }
    };

    const currentContainer = containerRef.current;

    const resizeObserver = new ResizeObserver(() => {
      observeSize();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    observeSize();

    return () => {
      if (currentContainer) {
        resizeObserver.unobserve(currentContainer);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const renderContent = () => {
    if (imageCardContent.imageType === "image") {
      return (
        <Image
          src={imageCardContent.image}
          layout="fill"
          objectFit="cover"
          priority={item.y < 4}
        />
      );
    } else if (imageCardContent.imageType === "video") {
      return (
        <video
          ref={videoRef}
          src={imageCardContent.image}
          style={{ objectFit: "cover" }}
          autoPlay={true}
          playsInline
          muted
          loop
        ></video>
      );
    }
  };

  return (
    <div className={gridStyles.imageItem} ref={containerRef}>
      <div
        className={gridStyles.imageItemContainer}
        style={{
          width: dimensions.width,
          height: dimensions.height,
        }}
      >
        {renderContent()}
        {card.layout === "Resize" ? children : null}
      </div>
      {imageCardContent.caption && (
        <span
          className={gridStyles.imageItemCaption}
          style={{
            maxWidth: (containerRef.current?.clientWidth || 100) - 39,
          }}
        >
          {imageCardContent.caption}
        </span>
      )}
      {imageCardContent.imageType === "video" && (
        <div
          className={gridStyles.imageItemVolume}
          onClick={handleItemClick}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {muted ? <Icon icon="mute" /> : <Icon icon="volume_up" />}
        </div>
      )}
    </div>
  );
}
