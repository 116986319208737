import React, { useContext } from "react";
import { useGridActions } from "@/hooks/useGridActions";
import gridStyles from "@/styles/grids.module.scss";
import { CardContent } from "@/types/CardContent";
import { GridLayoutContext } from "@/contexts/GridLayoutContext";
import GridButton from "@/components/GridItems/GridButton";
import Api from "@/api";
import { useAuthHeaders } from "@/lib/authHeader";

export default function GridButtonDelete({ card }: { card: CardContent }) {
  const { state } = useContext(GridLayoutContext);
  const { deleteItem } = useGridActions();
  const { authHeader } = useAuthHeaders();

  const handleDeleteClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!authHeader) return;

    deleteItem(card.id);

    try {
      const res = await Api.MyGridLayout.deleteApiGridLayoutsMeDelete(
        card.id as string,
        authHeader
      );
    } catch (error) {
      console.log(error);
    }
  };
  const selected = state.selectedCardId === card.id;

  return (
    <GridButton
      selected={selected}
      icon="card_trash"
      onClick={handleDeleteClick}
      additionalClasses={gridStyles.buttonTrash}
    />
  );
}
